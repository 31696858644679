.hello {
  color: transparent;
  background: url(https://media3.giphy.com/media/5SxdtXRaHYoG0QFa7P/giphy.gif);
  background-size: cover;
  background-position: center;
  background-clip: text;
  -webkit-background-clip: text;
  font-size: 48px;
  font-weight: bold;
}

.imgGallery {
  column-count: 3;
  column-gap: 1em;
  margin-top: 30px;
}

.galleryCard {
  display: inline-block;
  margin: 0 0 1em;
  width: 100%;
}

.rotatingText {
  align-items: center;
  display: flex;
  text-align: center;
}

.rotatingText-content {
  position: relative;
  width: 100%;
}

.rotatingText-description {
  font-family: "Georgia", serif;
  font-style: italic;
  font-size: 14px;
  margin: 0;
}
@media (min-width: 768px) {
  .rotatingText-description {
    font-size: 18px;
  }
}

.rotatingText-adjective {
  font-family: "Open Sans", sans-serif;
  font-size: 40px;
  left: 0;
  margin-bottom: 0;
  margin-top: 30px;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  top: 0;
}
@media (min-width: 768px) {
  .rotatingText-adjective {
    font-size: 70px;
  }
}
.rotatingText-adjective:nth-of-type(1) {
  animation: rotate-text-up 1.5s 0.75s;
}
.rotatingText-adjective:nth-of-type(2) {
  animation: rotate-text-up 1.5s 2s;
}
.rotatingText-adjective:nth-of-type(3) {
  animation: fade-text-in 1.5s 3.25s forwards;
}

@keyframes rotate-text-up {
  0% {
    transform: translate3d(0, 80px, 0);
    opacity: 0;
  }
  20%, 80% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, -40px, 0);
    opacity: 0;
  }
}
@keyframes fade-text-in {
  0% {
    opacity: 0;
    transform: translate3d(0, 80px, 0);
  }
  50%, 100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.twitter {
  bottom: 0;
  position: fixed;
  right: 0;
  width: 50px;
}
